@import '/src/mixins';

.user-preferences-page {
  height: 100vh;
  width: 100%;
  padding: 2rem;
  overflow: auto;

  h2 {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  h3 {
    margin: 0;
  }
}

.preferences-page-header {
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 10rem auto;
  text-align: center;
}

.preferences-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

hr {
  width: 100%;
}

.preferences-radio-buttons {
  label {
    padding: 1rem;
  }
  padding-bottom: 1rem;
}

.submit-button-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.notification-prefs-button-container {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
  .css-1endg80-MuiPaper-root-MuiAlert-root {
    margin-top: 16px;
  }
}

.default-history-selects {
  font-size: 12px;
}

.default-history-select-menu-items {
  font-size: 12px;
}
