@import 'css-variables';
@import 'mixins';

:root[data-theme='GEOLLECT'] {
  body {
    scrollbar-color: #396a9a #2a395c;
  }
  @include spread-map($theme-map-geollect);
}
:root[data-theme='RISK_INTELLIGENCE'] {
  @include spread-map($theme-map-risk-intelligence);
}
