@import 'themes';
@import 'css-variables';

:root,
[data-amplify-theme] {
  --amplify-components-authenticator-form-padding: '2rem';
  --amplify-components-authenticator-container-width-max: '361px'
    --amplify-components-;
}

[data-amplify-authenticator] [data-amplify-container] {
  display: flex;
  flex-direction: column;
  align-items: center;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 28px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

[data-amplify-authenticator] [data-amplify-router-content] {
  width: 100%;
}

[data-amplify-authenticator-signin] {
  .amplify-button[data-variation='primary'] {
    background-color: theme-var($--signin-button-colour);
  }
}

[data-amplify-authenticator] [data-amplify-footer] {
  padding-top: 1rem;

  .amplify-button[data-variation='link'] {
    color: theme-var($--primary-item-colour);
  }
  .amplify-button[data-variation='link']:hover {
    background-color: theme-var($--secondary-hover-colour);
  }
}

:root[data-theme='GEOLLECT'] {
  [data-amplify-authenticator-signin] {
    .amplify-button[data-variation='primary'] {
      background-color: #12284c;
    }
  }

  [data-amplify-authenticator] [data-amplify-footer] {
    padding-top: 1rem;

    .amplify-button[data-variation='link'] {
      color: #12284c;
    }
    .amplify-button[data-variation='link']:hover {
      background-color: theme-var($--secondary-hover-colour);
    }
  }
}

:root[data-theme='RISK_INTELLIGENCE'] .auth-header {
  position: absolute;
  top: 4rem;
  background-image: url('assets/ri-auth-header.png');
  width: 408px;
  height: 41px;
  background-size: contain;
  background-repeat: no-repeat;

  &.mobile {
    width: 90%;
  }
}

.login-container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: url('assets/background-login-page.jpg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  background-color: #131111;
}

:root[data-theme='RISK_INTELLIGENCE'] .login-container {
  background-image: url('assets/ri-background.jpg');
}
