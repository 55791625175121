@import '/src/mixins';

.loading-screen {
  background-color: theme-var($--primary-background-colour) !important;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
